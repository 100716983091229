import React from 'react';

const Breadcrumbs = ({ page, isShowStatus, statusName, statusColour, otherStatusName, otherStatusColour, items }) => {
    return (
        <>
            <div className="page-header"
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    backgroundColor: '#f8f9fa',
                    zIndex: '1000',
                    padding: '15px 0',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                }}
            >
                <h1 className="fw-bold" style={{ color: '#2A2F5B', marginLeft: '12%' }}>{page}</h1>
                <ul className="breadcrumbs">
                    <li className="nav-home">
                        <a href="/">
                            <i className="icon-home fw-bold" style={{ color: '#EF6C00' }}></i>
                        </a>
                    </li>
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            <li className="separator fw-bold" style={{ color: '#EF6C00' }}>
                                <i className="icon-arrow-right fw-bold"></i>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="fw-bold"
                                    style={item.style || { color: '#EF6C00' }}
                                    href={item.url}
                                >
                                    {item.name}
                                </a>
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
                {isShowStatus && (
                    <div style={{ display: 'flex', marginLeft: '30px' }}>
                        <div className="page-header col-12" style={{ marginBottom: '-1px' }}>
                            <button
                                className="btn btn-lg w-100"
                                style={{
                                    backgroundColor: statusColour,
                                    fontSize: '18px',
                                    color: 'white'
                                }}
                            >
                                {statusName}
                            </button>
                        </div>
                    </div>
                )}

                {/* ใช้สำหรับใบรับสินค้า แสดงสถานะจ่าย && แสดงสถานะการจัดส่งของ Picking List */}
                {otherStatusName && otherStatusColour && (
                    <div style={{ display: 'flex', marginLeft: '30px' }}>
                        <div className="page-header col-12" style={{ marginBottom: '-1px' }}>
                            <button
                                className="btn btn-lg w-100"
                                style={{
                                    backgroundColor: otherStatusColour,
                                    fontSize: '18px',
                                    color: 'white'
                                }}
                            >
                                {otherStatusName}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ marginTop: '5%' }} />
        </>
    );
};

export default Breadcrumbs;