import React, { useState, useEffect } from 'react';
import Axios from "axios";
import './../../../../assets/css/purchase/form.css';

// React DateTime
import Datetime from 'react-datetime';
import moment from 'moment';

// Components
import Breadcrumbs from '../../Breadcrumbs';
import ArModal from '../../Modal/ArModal';
import SoModal from '../../Modal/SoModal';
import BolModal from '../../Modal/BolModal';
import FormAction from '../../Actions/FormAction';

// Model
import { pkListMasterModel } from '../../../../model/SellProducts/PkListMasterModel';
import { pkListDetailModel } from '../../../../model/SellProducts/PkListDetailModel';

// Utils
import {
  getDataWithComp,
  deleteDetail,
  getMaxDocNo,
  getMaxWdNo,
  getAlert,
  getData
} from '../../../../utils/SamuiUtils';

import {
  formatDate,
  formatStringDateToDate,
  formatDateOnChange,
  formatThaiDateUi,
  formatThaiDateUiToDate,
  getCreateDateTime,
  setCreateDateTime
} from '../../../../utils/DateUtils';

function Form({ callInitialize, mode, name, maxDocNo }) {
  const [formMasterList, setFormMasterList] = useState(pkListMasterModel());
  const [formDetailList, setFormDetailList] = useState([]);
  const [arDataList, setArDataList] = useState([]);
  const [soDataList, setSoDataList] = useState([]);
  const [dlLineList, setDlLineList] = useState([]);

  // รายละเอียดการส่งสินค้า
  const [itemList, setItemList] = useState([]);

  // รถส่งสินค้า
  const [carList, setCarList] = useState([]);

  // คนขับรถส่งสินค้า
  const [driverList, setDriverList] = useState([]);

  // การแสดงสถานะใบ
  const [statusName, setStatusName] = useState("");
  const [statusColour, setStatusColour] = useState("");
  const [reqStcStatusName, setReqStcStatusName] = useState("");
  const [reqStcStatusColour, setReqStcStatusColour] = useState("");

  // ตัวแปรประเภทการดึงข้อมูลจาก SoModal (SO = ใบขาย, DL = สายส่ง)
  const [selectedType, setSelectedType] = useState("");

  // การใช้ Tab เพื่อเปลี่ยน Form
  const [activeTab, setActiveTab] = useState('deliveryDetails');

  // ใช้สำหรับเก็บค่าสูงของ WD (ใบเบิก)
  const [maxWdNo, setMaxWdNo] = useState();

  // ใช้สำหรับแสดงสินค้าคงคลัง
  const [formItemOnHandCaseTab, setFormItemOnHandCaseTab] = useState([]);

  useEffect(() => {
    initialize();
  }, []);

  // รอบให้ Default ตามเวลาปัจจุบัน แต่ยังคงให้เลือกได้อยู่ แค่ Default เฉยๆ (รอพี่แบงค์ทดสอบ)
  // => เช้า 06:00 - 11:59
  // => บ่าย 12:00 - 17:59
  // => ค่ำ 18:00 - 05:59
  useEffect(() => {
    const currentHour = new Date().getHours();
    let round;

    if (currentHour >= 6 && currentHour < 12) {
      round = '1'; // 1 = รอบเช้า (อ้างอิงจาก PKList_H)
    } else if (currentHour >= 12 && currentHour < 18) {
      round = '2'; // 2 = รอบบ่าย (อ้างอิงจาก PKList_H)
    } else {
      round = '3'; // 3 = รอบค่ำ (อ้างอิงจาก PKList_H)
    }

    setFormMasterList((prevState) => ({
      ...prevState,
      pkRound: round,
    }));
  }, []);

  const initialize = async () => {
    try {
      const arDataList = await getDataWithComp('Tb_Set_AR', 'ORDER BY AR_Code ASC');
      if (arDataList && arDataList.length > 0) {
        setArDataList(arDataList);
      }

      const soDataList = await getDataWithComp('API_0701_SO_H', 'ORDER BY Doc_No DESC');
      if (soDataList && soDataList.length > 0) {
        setSoDataList(soDataList);
      }

      const dlDataList = await getDataWithComp('DL_Line_H', 'ORDER By DL_Id ASC');
      if (dlDataList && dlDataList.length > 0) {
        setDlLineList(dlDataList);
      }

      // หาค่าสูงของ WHDocNo ใน WHDOC_H สำหรับ WD (ใบเบิก)
      const findMaxWdNo = await getDataWithComp('WHDoc_H', `AND WHDoc_Type = '3' ORDER BY WHDoc_No DESC`);
      const maxWd = getMaxWdNo(findMaxWdNo);
      setMaxWdNo(maxWd);

      // สำหรับ View เข้ามาเพื่อแก้ไขข้อมูล
      if (mode === 'U') {
        await getModelByNo(arDataList);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
    }
  };

  const getModelByNo = async (arDataList) => {
    try {
      const findMaster = await getDataWithComp('PKList_H', ``);
      const fromDatabase = findMaster.find(pk => pk.Doc_No === maxDocNo);

      if (!fromDatabase) {
        throw new Error("ไม่พบข้อมูลเอกสาร");
      }

      // ค้นหาข้อมูล VIEW
      const findViewMaster = await getDataWithComp('API_0801_PKList_H', '');
      const fromView = findViewMaster.find(data => data.Doc_No === maxDocNo);
      setStatusName(fromView.DocStatus_Name);
      setStatusColour(fromView.DocStatus_Colour);
      setReqStcStatusName(fromView.RequestSTCStatus_Name);
      setReqStcStatusColour(fromView.RequestSTCStatus_Colour);

      // ค้นหาข้อมูลของ Detail ด้วย Doc_ID
      const fromDetail = await getData('PKList_D', `Doc_Id = ${fromDatabase.Doc_Id} ORDER BY DL_Queue ASC`);
      const fromViewDetail = await getData('API_0802_PKList_D', `Doc_Id = ${fromDatabase.Doc_Id} ORDER BY DL_Queue ASC`);

      const getSoByDocNo = async (soId) => {
        const viewSo = await getData('API_0701_SO_H', `Doc_ID = ${soId}`);
        return String(viewSo[0].Doc_No);
      };

      if (fromDetail[0].SO_Id !== null) {
        // ใบขาย
        setSelectedType("SO");
        setFormDetailList([]);

        const updatedFormDetails = await Promise.all(
          fromViewDetail.map(async (data) => ({
            docId: data.Doc_Id,
            dlQueue: data.DL_Queue,
            arId: data.AR_Id,
            arCode: data.AR_Code,
            arName: data.AR_Name,
            soId: data.SO_Id,
            docNo: await getSoByDocNo(data.SO_Id),
          }))
        );

        setFormDetailList(updatedFormDetails);
      } else {
        // สายส่ง
        setSelectedType("DL");
        setFormDetailList([]);

        const updatedFormDetails = fromDetail.map(dlLine => ({
          docId: dlLine.Doc_Id,
          dlQueue: dlLine.DL_Queue,
          arId: dlLine.DL_AR_Id,
          arCode: dlLine.AR_Code,
          arName: dlLine.AR_Name,
          soId: dlLine.SO_Id,
          docNo: dlLine.Doc_No,
        }));

        // Update state with the accumulated details
        setFormDetailList(updatedFormDetails);

        // หารถส่งสินค้า
        const findCarList = await getData('View_DL_Car', `DL_Car_id = ${fromViewDetail[0].PK_Car_Id}`);
        if (findCarList && findCarList.length > 0) {
          setCarList(findCarList);
        }

        // หาคนขับรถส่งสินค้า (ยังไม่มีข้อมูล Mockup ไปก่อน)
        const findDriverList = await getDataWithComp('Tb_Set_Employee', `AND Emp_Status = 'Y'`);
        if (findDriverList && findDriverList.length > 0) {
          setDriverList(findDriverList);
        }
      }

      setFormMasterList({
        docId: fromDatabase.Doc_Id,
        compId: fromDatabase.Comp_Id,
        docNo: fromDatabase.Doc_No,
        docDate: formatThaiDateUi(fromDatabase.Doc_Date || null),
        docStatus: fromDatabase.Doc_Status,
        reqStcStatus: fromDatabase.Request_STC_Status,
        deliveryLineId: fromDatabase.Delivery_Line_Id,
        pkCarId: fromDatabase.PK_Car_Id,
        pkCarNo: fromDatabase.PK_Car_No,
        pkRound: String(fromDatabase.PK_Round),
        pkDriver1Id: fromDatabase.PK_Driver1_Id,
        pkDriver2Id: fromDatabase.PK_Driver2_Id,
        pkDriver3Id: fromDatabase.PK_Driver3_Id,
        createdDate: setCreateDateTime(fromDatabase.Created_Date || null),
        createdByName: fromDatabase.Created_By_Name,
        createdById: fromDatabase.Created_By_Id,
        updateDate: setCreateDateTime(getCreateDateTime() || null),
        updateByName: window.localStorage.getItem('name'),
        updateById: window.localStorage.getItem('emp_id'),
        cancelDate: setCreateDateTime(fromDatabase.Cancel_Date || null),
        cancelByName: fromDatabase.Cancel_By_Name,
        cancelById: fromDatabase.Cancel_By_Id,
      });

      // เรียกใช้ฟังชั่นดึงข้อมูลสินค้าคงเหลือ
      await onLoadOnHandCaseTab(fromDatabase.PK_Car_No);
    } catch (error) {
      getAlert("FAILED", error.message || error);
    }
  };

  // API_1101_WH_ITEM_ONHAND (สินค้าคงคลัง)
  const onLoadOnHandCaseTab = async (whCode) => {
    try {
      if (whCode != null) {
        const itemOnHandCaseTab = await getDataWithComp('API_1101_WH_ITEM_ONHAND', `AND WH_Code = '${whCode}' ORDER BY Item_Code ASC`);

        if (itemOnHandCaseTab && itemOnHandCaseTab.length > 0) {
          setFormItemOnHandCaseTab(itemOnHandCaseTab);
        }
      } else {
        setFormItemOnHandCaseTab([]);
      }
    } catch (error) {
      getAlert('FAILED', error.message);
      setFormItemOnHandCaseTab([]);
    }
  };

  const handleSubmit = async () => {
    try {
      // หาค่าสูงของ DocNo ใน PKList_H ก่อนบันทึก
      const findMaxDocNo = await getDataWithComp('PKList_H', 'ORDER BY Doc_No DESC');
      const maxDoc = getMaxDocNo(findMaxDocNo, 'PK');
      let newMaxDoc = maxDoc;

      // ตรวจสอบว่า formMasterList ต้องระบุรอบด้วย
      // if (formMasterList.pkRound === null || formMasterList.pkRound === '') {
      //   getAlert("FAILED", "กรุณาเลือกรอบ");
      //   return;
      // }

      // ตรวจสอบว่า formDetailList มีค่าหรือมีความยาวเป็น 0
      if (!formDetailList || formDetailList.length === 0) {
        getAlert("FAILED", "กรุณาเพิ่มอ้างอิงเอกสารสำหรับทำใบ Picking List");
        return; // หยุดการทำงานของฟังก์ชันหาก formDetailList ไม่มีค่า
      }

      // ตรวจสอบการเลือกรถส่งสินค้า
      if (formMasterList.pkCarId === null || formMasterList.pkCarId === '') {
        getAlert("FAILED", "กรุณาเลือกรถส่งสินค้า");
        return;
      }

      // ตรวจสอบการเลือกคนขับรถส่งสินค้าอย่างน้อย 1 คน
      if ((formMasterList.pkDriver1Id === null || formMasterList.pkDriver1Id === '') &&
        (formMasterList.pkDriver2Id === null || formMasterList.pkDriver2Id === '') &&
        (formMasterList.pkDriver3Id === null || formMasterList.pkDriver3Id === '')) {
        getAlert("FAILED", "กรุณาเลือกคนขับรถส่งสินค้าอย่างน้อย 1 คน");
        return;
      }

      // ค้นหาทะเบียนรถ
      const getCarNoByCarId = async (pkCarId) => {
        if (pkCarId === null || pkCarId === '') {
          return null;
        }
        const viewCar = await getData('Tb_Set_Car', `Car_id = ${pkCarId}`);
        return String(viewCar[0].Car_No);
      };

      // ข้อมูลหลักที่จะส่งไปยัง API
      const formMasterData = {
        comp_id: window.localStorage.getItem('company'),
        doc_no: newMaxDoc,
        doc_date: formatStringDateToDate(formMasterList.docDate),
        doc_status: parseInt("1", 10),
        req_stc_status: parseInt("0", 10),
        delivery_line_id: formMasterList.deliveryLineId,
        pk_car_id: formMasterList.pkCarId,
        pk_car_no: await getCarNoByCarId(formMasterList.pkCarId || null),
        pk_round: parseInt(formMasterList.pkRound, 10),
        pk_driver_1_id: formMasterList.pkDriver1Id,
        pk_driver_2_id: formMasterList.pkDriver2Id,
        pk_driver_3_id: formMasterList.pkDriver3Id,
        created_date: formatThaiDateUiToDate(formMasterList.createdDate),
        created_by_name: window.localStorage.getItem('name'),
        created_by_id: window.localStorage.getItem('emp_id'),
        update_date: null,
        update_by_name: null,
        update_by_id: null,
        cancel_date: null,
        cancel_by_name: null,
        cancel_by_id: null
      };

      // ส่งข้อมูลหลักไปยัง API
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-pk-list-h`, formMasterData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      // ตรวจสอบสถานะการตอบกลับ
      if (response.data.status === 'OK') {
        const getDocIdResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-by-doc-no`, {
          table: 'PKList_H',
          doc_no: formMasterData.doc_no
        }, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        // ส่งข้อมูลรายละเอียดหากพบ Doc_Id
        if (getDocIdResponse && getDocIdResponse.data.length > 0) {
          const detailPromises = formDetailList.map(async (item, index) => {
            const formDetailData = {
              doc_id: parseInt(getDocIdResponse.data[0].Doc_Id, 10),
              dl_queue: index + 1,
              ar_id: item.arId,
              ar_code: item.arCode,
              ar_name: item.arName,
              so_id: item.soId
            };

            return Axios.post(`${process.env.REACT_APP_API_URL}/api/create-pk-list-d`, formDetailData, {
              headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            });
          });

          await Promise.all(detailPromises);

        }

        callInitialize();
        getAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      getAlert("FAILED", error.response?.data?.message || error.message);
    }
  };

  const handleUpdate = async () => {
    try {
      // ตรวจสอบว่า formMasterList ต้องระบุรอบด้วย
      // if (formMasterList.pkRound === null || formMasterList.pkRound === '') {
      //   getAlert("FAILED", "กรุณาเลือกรอบ");
      //   return;
      // }

      // ตรวจสอบว่า formDetailList มีค่าหรือมีความยาวเป็น 0
      if (!formDetailList || formDetailList.length === 0) {
        getAlert("FAILED", "กรุณาเพิ่มอ้างอิงเอกสารสำหรับทำใบ Picking List");
        return; // หยุดการทำงานของฟังก์ชันหาก formDetailList ไม่มีค่า
      }

      // เฉพาะแก้ไขข้อมูลของสายส่ง
      if (selectedType === 'DL') {
        if (formMasterList.pkCarId === null || formMasterList.pkCarId === '') {
          getAlert("FAILED", "กรุณาเลือกรถส่งสินค้า");
          return;
        }
        if ((formMasterList.pkDriver1Id === null || formMasterList.pkDriver1Id === '') &&
          (formMasterList.pkDriver2Id === null || formMasterList.pkDriver2Id === '') &&
          (formMasterList.pkDriver3Id === null || formMasterList.pkDriver3Id === '')) {
          getAlert("FAILED", "กรุณาเลือกคนขับรถส่งสินค้าอย่างน้อย 1 คน");
          return;
        }
      }

      // ข้อมูลหลักที่จะส่งไปยัง API
      const formMasterData = {
        comp_id: window.localStorage.getItem('company'),
        doc_no: formMasterList.docNo,
        doc_date: formatStringDateToDate(formMasterList.docDate),
        doc_status: formMasterList.docStatus,
        req_stc_status: formMasterList.reqStcStatus,
        delivery_line_id: formMasterList.deliveryLineId,
        pk_car_id: formMasterList.pkCarId,
        pk_car_no: formMasterList.pkCarNo,
        pk_round: parseInt(formMasterList.pkRound, 10),
        pk_driver_1_id: formMasterList.pkDriver1Id,
        pk_driver_2_id: formMasterList.pkDriver2Id,
        pk_driver_3_id: formMasterList.pkDriver3Id,
        created_date: formatThaiDateUiToDate(formMasterList.createdDate),
        created_by_name: formMasterList.createdByName,
        created_by_id: formMasterList.createdById,
        update_date: formatThaiDateUiToDate(formMasterList.updateDate),
        update_by_name: window.localStorage.getItem('name'),
        update_by_id: window.localStorage.getItem('emp_id'),
        cancel_date: formMasterList.cancelDate,
        cancel_by_name: formMasterList.cancelByName,
        cancel_by_id: formMasterList.cancelById
      };

      // ส่งข้อมูลหลักไปยัง API
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/update-pk-list-h`, formMasterData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      // ตรวจสอบสถานะการตอบกลับ
      if (response.data.status === 'OK') {

        // ลบข้อมูลเดิมก่อนจะเริ่มการบันทึกใหม่
        await deleteDetail('PKList_D', `WHERE Doc_ID = ${formMasterList.docId}`);

        const docId = parseInt(formMasterList.docId, 10);

        const detailPromises = formDetailList.map(async (item, index) => {
          const formDetailData = {
            doc_id: parseInt(docId, 10),
            dl_queue: index + 1,
            ar_id: item.arId,
            ar_code: item.arCode,
            ar_name: item.arName,
            so_id: item.soId
          };

          return Axios.post(`${process.env.REACT_APP_API_URL}/api/create-pk-list-d`, formDetailData, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });
        });

        await Promise.all(detailPromises);

        callInitialize();
        getAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      getAlert("FAILED", error.response?.data?.message || error.message);
    }
  };

  const handleCancel = async () => {
    try {
      // ข้อมูลหลักที่จะส่งไปยัง API
      const formMasterData = {
        doc_no: formMasterList.docNo,
        doc_status: parseInt("13", 10),
        cancel_date: formatThaiDateUiToDate(getCreateDateTime()),
        cancel_by_name: window.localStorage.getItem('name'),
        cancel_by_id: window.localStorage.getItem('emp_id'),
      };

      // ส่งข้อมูลหลักไปยัง API
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/cancel-pk-list-h`, formMasterData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      callInitialize();
      getAlert(response.data.status, response.data.message);
    } catch (error) {
      getAlert("FAILED", error.response?.data?.message || error.message);
    }
  };

  const handleChangeMaster = async (e) => {
    const { name, value } = e.target;

    if (name === 'pkCarId') {
      // กรองหาข้อมูลที่ต้องการจาก carList
      const getCarNo = carList.find(car => {
        const carId = selectedType === 'SO' ? car.Car_id : car.DL_Car_id;

        // เปรียบเทียบ carId กับ value โดยแปลงเป็น String
        return carId.toString() === value.toString();
      });

      // ถ้าพบข้อมูลของรถ ให้ทำการเพิ่มค่าใน formMasterList.pkCarNo
      if (getCarNo) {
        setFormMasterList((prev) => ({
          ...prev,
          [name]: value,
          pkCarNo: getCarNo.Car_No,  // ใช้ Car_No แทน DL_Car_No ถ้า DL_Car_No ไม่พบ
        }));

        // เรียกใช้ฟังชั่นดึงข้อมูลสินค้าคงเหลือ
        await onLoadOnHandCaseTab(getCarNo.Car_No);

        return;
      }
    }

    // อัปเดตค่าใน formMasterList ปกติสำหรับ field อื่น ๆ
    setFormMasterList((prev) => ({
      ...prev,
      [name]: value,
    }));


  };

  const handleChangeDateMaster = (value, name) => {
    // ตรวจสอบว่า value เป็น moment object หรือไม่
    const newValue = value && value instanceof moment ? value.format('YYYY-MM-DD') : value;

    // อัปเดตค่าใน formMasterList
    setFormMasterList((prev) => ({
      ...prev,
      [name]: formatDateOnChange(newValue),
    }));
  };

  // SET SO
  const [showSoModal, setShowSoModal] = useState(false);
  const handleSoShow = () => setShowSoModal(true);
  const handleSoClose = () => setShowSoModal(false);
  const onRowSelectSo = async (soSelected) => {
    try {
      setSelectedType("SO");

      // ค้นหาลูกค้าเงินสด
      const cashCustomerData = await getDataWithComp('Tb_Set_AR', `AND AR_Name LIKE '%เงินสด%'`);
      const cashCustomer = cashCustomerData[0] || null;

      const soSelectedList = soSelected.map((item, index) => ({
        docId: null,
        dlQueue: index + 1,
        arId: item.AR_ID,
        arCode: item.AR_Code,
        arName: item.AR_Name,
        soId: item.Doc_ID,
        docNo: item.Doc_No
      }));

      // ตรวจสอบว่ามีข้อมูล cashCustomer ไหม
      if (cashCustomer) {
        // แทรกข้อมูล cashCustomer ที่ตัวแรกเสมอ
        soSelectedList.splice(0, 0, {
          docId: null,
          dlQueue: 1,
          arId: cashCustomer.AR_ID,
          arCode: cashCustomer.AR_Code,
          arName: cashCustomer.AR_Name,
          soId: null,
          docNo: null
        });
      }

      setFormDetailList(soSelectedList);
      handleSoClose();
    } catch (error) {
      getAlert("FAILED", error.message || error);
    }
  };
  const onConfirmSoSelection = async (soSelected) => {
    try {
      if (!soSelected[0]) {
        getAlert("FAILED", "ท่านยังไม่ได้เลือกใบขายสินค้า");
        return;
      }

      setFormDetailList([]);
      setCarList([]);
      setDriverList([]);

      onRowSelectSo(soSelected);

      // หารถส่งสินค้า && หาคนขับรถส่งสินค้า
      await fetchCarAndDriverList(``);

      handleSoClose();

      // แจ้งเตือนผู้ใช้ว่าการเลือกสำเร็จ
      getAlert("OK", "การเลือกใบขายสินค้าสำเร็จ");
    } catch (error) {
      getAlert("FAILED", error);
    }
  };

  // SET DL
  const onRowSelectDL = async (dlSelected) => {
    try {
      // เก็บประเภทการดึงข้อมูลจาก SoModal && รีเซ็ตค่า formDetailList
      setSelectedType("DL");
      setFormDetailList([]);

      // หาสายส่ง
      const findLineList = await getData('View_DL_Line', `DL_Id = ${dlSelected.DL_Id}`);

      // ค้นหาลูกค้าเงินสด
      const cashCustomerData = await getDataWithComp('Tb_Set_AR', `AND AR_Name LIKE '%เงินสด%'`);
      const cashCustomer = cashCustomerData[0] || null; // เลือกข้อมูลลูกค้าเงินสดตัวแรกหรือเป็น null

      // ใช้ array ชั่วคราวในการเก็บรายละเอียดฟอร์ม
      let updatedFormDetails = [];

      if (cashCustomer) {
        // แทรกข้อมูลลูกค้าเงินสดที่ index 0
        updatedFormDetails.push({
          docId: null,
          dlQueue: 1, // ตั้งค่าลำดับเป็น 1 หรือค่าที่เหมาะสม
          arId: cashCustomer.AR_ID,
          arCode: cashCustomer.AR_Code,
          arName: cashCustomer.AR_Name,
          soId: null,
          docNo: null,
        });
      }

      if (findLineList && findLineList.length > 0) {
        // เพิ่มรายละเอียดสายส่งลงใน updatedFormDetails
        const dlDetails = findLineList.map(dlLine => ({
          docId: null,
          dlQueue: dlLine.DL_Queue,
          arId: dlLine.DL_AR_Id,
          arCode: dlLine.AR_Code,
          arName: dlLine.AR_Name,
          soId: null,
          docNo: null,
        }));

        // รวมข้อมูลลูกค้าเงินสดและรายละเอียดสายส่ง
        updatedFormDetails = updatedFormDetails.concat(dlDetails);
      }

      // อัพเดต state ด้วยรายละเอียดที่รวมกัน
      setFormDetailList(updatedFormDetails);

      // หารถส่งสินค้า && หาคนขับรถส่งสินค้า
      await fetchCarAndDriverList(dlSelected.DL_Id);

      handleSoClose();
    } catch (error) {
      getAlert("FAILED", error.message || error);
    }
  };

  // SET AR
  const [showArModal, setShowArModal] = useState(false);
  const handleArShow = () => setShowArModal(true);
  const handleArClose = () => setShowArModal(false);
  const onRowSelectAr = (arSelected) => {
    try {
      // สร้างรายการใหม่ที่จะเพิ่มเข้าไปใน formMasterList
      const newRow = {
        docId: null,
        dlQueue: Number(formDetailList.length) + 1,
        arId: arSelected.AR_Id,
        arCode: arSelected.AR_Code,
        arName: arSelected.AR_Name,
        soId: null,
        docNo: null,
      };

      setFormDetailList(prevState => {
        if (prevState.length === 0) {
          // ถ้า formMasterList ว่างเปล่า, ให้สร้างรายการใหม่
          return [newRow];
        }

        // ตรวจสอบว่ามีข้อมูลเดียวกันในรายการแล้วหรือไม่
        const isAlreadyPresent = prevState.some(item => item.arId === arSelected.AR_Id);

        if (isAlreadyPresent) {
          // ถ้ามีข้อมูลอยู่แล้ว ให้คืนค่าเดิม
          return prevState;
        }

        // เพิ่มรายการใหม่ถ้ายังไม่มี
        return [...prevState, newRow];
      });

      handleArClose(); // ปิด modal หลังจากเลือก
    } catch (error) {
      getAlert("FAILED", error.message || error);
    }
  };

  // SET BOL
  const [showBolModal, setShowBolModal] = useState(false);
  const handleBolShow = () => setShowBolModal(true);
  const handleBolClose = () => setShowBolModal(false);

  const fetchCarAndDriverList = async (dlId) => {
    let findCarList;

    if (dlId) {
      // ถ้า dlId มีค่า
      const carCondition = `AND DL_Id = ${dlId}`;
      findCarList = await getDataWithComp('View_DL_Car', carCondition);
    } else {
      // ถ้า dlId ไม่มีค่า
      findCarList = await getDataWithComp('Tb_Set_Car', `AND Car_Status = 'Y'`);
    }

    if (findCarList && findCarList.length > 0) {
      setCarList(findCarList);
    } else {
      setCarList([]); // เผื่อกรณีไม่มีข้อมูล
    }

    // หาคนขับรถส่งสินค้า
    const findDriverList = await getDataWithComp(
      'Tb_Set_Employee',
      `AND Emp_Status = 'Y' AND Emp_Position IN (7,8,9)`
    );
    if (findDriverList && findDriverList.length > 0) {
      setDriverList(findDriverList);
    } else {
      setDriverList([]); // เผื่อกรณีไม่มีข้อมูล
    }
  };

  // แสดงรายละเอียดการส่งสินค้า (แค่แสดงเฉยๆ ไม่ได้ Map Field ข้อมูลไปบันทึก)
  const handleDisplayItem = async (data) => {
    try {
      // ดึงข้อมูลสินค้าจาก API ตาม docId ของลูกค้า
      const findItemDetails = await getData('API_0702_SO_D', `Doc_ID = ${data.soId}`);

      const itemDetailList = findItemDetails.map((item, index) => ({
        soId: item.Doc_ID,
        docNo: item.Doc_No,
        itemCode: item.Item_Code,
        itemName: item.Item_Name,
        itemQty: item.Item_Qty,
        itemUnit: item.Item_Unit,
      }));

      // แทนที่ข้อมูลเก่าด้วยข้อมูลใหม่
      setItemList(itemDetailList);
    } catch (error) {
      getAlert("FAILED", error.message || error);
    }
  };

  // ลบข้อมูลรายชื่อลูกค้า
  const handleRemove = (index, event) => {
    try {
      // ไม่ให้มันไปเข้าฟังชั่น handleDisplayItem
      event.stopPropagation();

      // ตรวจสอบว่ามีข้อมูลมากกว่า 1 รายการ
      if (formDetailList.length > 1) {
        // อัปเดต formMasterList โดยการกรองออกแถวที่ต้องการลบ
        setFormDetailList(prevState => {
          // กรองรายการที่ไม่ใช่รายการที่ต้องการลบ
          const updatedList = prevState.filter((_, i) => i !== index);
          return updatedList;
        });
      } else {
        // แจ้งเตือนถ้ามีรายการเหลือเพียง 1 รายการ
        getAlert("FAILED", "ไม่สามารถลบข้อมูลลูกค้าได้ เนื่องจากการสร้างใบ Picking List ต้องมีข้อมูลลูกค้าอย่างน้อย 1 คน");
      }
    } catch (error) {
      getAlert("FAILED", error);
    }
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("dragItemIndex", index);
  };

  const handleDrop = (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData("dragItemIndex");
    if (dragIndex === dropIndex) return;

    const updatedItems = [...formDetailList];
    const [draggedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(dropIndex, 0, draggedItem);

    setFormDetailList(updatedItems);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    const updatedItems = [...formDetailList];
    const temp = updatedItems[index];
    updatedItems[index] = updatedItems[index - 1];
    updatedItems[index - 1] = temp;
    setFormDetailList(updatedItems);
  };

  const handleMoveDown = (index) => {
    if (index === formDetailList.length - 1) return;
    const updatedItems = [...formDetailList];
    const temp = updatedItems[index];
    updatedItems[index] = updatedItems[index + 1];
    updatedItems[index + 1] = temp;
    setFormDetailList(updatedItems);
  };

  return (
    <>
      <Breadcrumbs page={maxDocNo}
        isShowStatus={mode === 'U'}
        statusName={statusName}
        statusColour={statusColour}
        otherStatusName={reqStcStatusName}
        otherStatusColour={reqStcStatusColour}
        items={[
          { name: 'ขายสินค้า', url: '/sellproducts' },
          { name: name, url: '/picking-list' },
          { name: mode === 'U' ? "เรียกดู " + name : "สร้าง " + name, url: '#' },
        ]}
      />
      <div className="row mt-1">
        <div className="col-3 text-left">
          <div className="d-flex align-items-center">
            <label>วันที่เอกสาร</label>
            <Datetime
              className="input-spacing-input-date"
              name="docDate"
              value={formMasterList.docDate || new Date()}
              onChange={(date) => handleChangeDateMaster(date, 'docDate')}
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              inputProps={{ readOnly: true, disabled: mode === 'U' }}
            />
          </div>
        </div>
        <div className="col-4 text-left">
          <div className="d-flex align-items-center">
            <label>รถส่งสินค้า</label>
            <select
              name="pkCarId"
              value={formMasterList?.pkCarId || ""}
              onChange={(e) => handleChangeMaster(e)}
              className="form-select form-control input-spacing"
              disabled={selectedType === ""}
            >
              <option value="">กรุณาเลือกรถส่งสินค้า</option>
              {carList
                .filter(car => car.Car_No && car.Car_Name)
                .map((car, index) => {
                  // คำนวณค่า value ที่จะใช้
                  const carId = selectedType === 'SO' ? car.Car_id : car.DL_Car_id;
                  return (
                    <option key={index + 1} value={carId}>
                      {car.Car_No} ({car.Car_Name})
                    </option>
                  );
                })
              }
            </select>
          </div>
        </div>
        <div className="col-2" />
        <div className="col-3 text-right">
          <div className="d-flex align-items-center">
            <label>วันที่สร้างเอกสาร</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="createdDate"
              value={formMasterList.createdDate}
              onChange={(e) => handleChangeMaster(e)}
              disabled={true} />
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-3">
          <div className="d-flex align-items-center">
            <label>อ้างอิงเอกสาร</label>
            <div className="input-group">
              <input
                type="text"
                value={`ประเภทข้อมูล : ${selectedType !== '' ? (selectedType === 'SO' ? 'ใบขาย' : 'สายส่ง') : ''}`}
                className="form-control input-spacing"
                disabled={true}
              />
              <button
                className="btn btn-outline-secondary"
                onClick={handleSoShow}
                disabled={mode === 'U'}>
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <SoModal
            showSoModal={showSoModal}
            handleSoClose={handleSoClose}
            soDataList={soDataList}
            dlLineList={dlLineList}
            onConfirmSoSelection={onConfirmSoSelection}
            onRowSelectDL={onRowSelectDL}
          />
        </div>
        <div className="col-4">
          <div className="d-flex align-items-center">
            <label>คนขับรถส่งสินค้าที่ 1</label>
            <select
              name="pkDriver1Id"
              value={formMasterList.pkDriver1Id}
              onChange={(e) => handleChangeMaster(e)}
              className="form-select form-control input-spacing"
              disabled={selectedType === ""}>
              <option value="">กรุณาเลือกคนขับรถส่งสินค้าที่ 1</option>
              {driverList.map((driver) => (
                <option key={driver.Emp_Id} value={driver.Emp_Id}>
                  {driver.Emp_Name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-2" />
        <div className="col-3 text-right">
          <div className="d-flex align-items-center">
            <label>ผู้สร้างเอกสาร</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="createdByName"
              value={formMasterList.createdByName || ''}
              onChange={(e) => handleChangeMaster(e)}
              disabled={true} />
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-3">
          <div className="d-flex align-items-center">
            <label>รอบ</label>
            <select
              name="pkRound"
              value={formMasterList.pkRound || ''}
              onChange={(e) => handleChangeMaster(e)}
              disabled={mode === 'U'}
              className="form-select form-control input-spacing"
            >
              <option value="1">เช้า</option>
              <option value="2">เย็น</option>
              <option value="3">ค่ำ</option>
            </select>
          </div>
        </div>
        <div className="col-4">
          <div className="d-flex align-items-center">
            <label>คนขับรถส่งสินค้าที่ 2</label>
            <select
              name="pkDriver2Id"
              value={formMasterList.pkDriver2Id}
              onChange={(e) => handleChangeMaster(e)}
              className="form-select form-control input-spacing"
              disabled={selectedType === ""}>
              <option value="">กรุณาเลือกคนขับรถส่งสินค้าที่ 2</option>
              {driverList.map((driver) => (
                <option key={driver.Emp_Id} value={driver.Emp_Id}>
                  {driver.Emp_Name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-2" />
        <div className="col-3 text-right">
          <div className="d-flex align-items-center">
            <label>วันที่แก้ไขล่าสุด</label>
            <input
              // type="date"
              type="text"
              className="form-control input-spacing"
              name="updateDate"
              value={formMasterList.updateDate || ''}
              onChange={(e) => handleChangeMaster(e)}
              disabled={true} />
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-3">
          {/* <div className="d-flex align-items-center">
            <label>ประเภทเอกสาร</label>
            <select
              className="form-select form-control input-spacing"
              name="docType"
              value={formMasterList.docType}
              onChange={handleChangeMaster}
              disabled={formMasterList.docStatus !== 1}
            >
              {tbDocType.map((docType) => (
                <option key={docType.DocType_Id} value={docType.DocType_Id}>
                  {docType.DocType_Name}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        <div className="col-4">
          <div className="d-flex align-items-center">
            <label>คนขับรถส่งสินค้าที่ 3</label>
            <select
              name="pkDriver3Id"
              value={formMasterList.pkDriver3Id}
              onChange={(e) => handleChangeMaster(e)}
              className="form-select form-control input-spacing"
              disabled={selectedType === ""}>
              <option value="">กรุณาเลือกคนขับรถส่งสินค้าที่ 3</option>
              {driverList.map((driver) => (
                <option key={driver.Emp_Id} value={driver.Emp_Id}>
                  {driver.Emp_Name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-2" />
        <div className="col-3 text-right">
          <div className="d-flex align-items-center">
            <label>ผู้แก้ไขเอกสาร</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="updateByName"
              value={formMasterList.updateByName || ''}
              onChange={(e) => handleChangeMaster(e)}
              disabled={true} />
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-3">
          {/* <div className="d-flex align-items-center">
            <label>วัตถุประสงค์</label>
            <select
              name="docFor"
              value={formMasterList.docFor}
              onChange={handleChangeMaster}
              className="form-select form-control input-spacing"
              disabled={formMasterList.docStatus !== 1}>
              <option value="1">ซื้อมาเพื่อใช้</option>
              <option value="2">ซื้อมาเพื่อขาย</option>
            </select>
          </div> */}
        </div>
        <div className="col-4">
          {/* <div className="d-flex align-items-center">
            <label></label>
            <input
              type="text"
              className="form-control input-spacing"
              value={formMasterList.arTaxNo || ''}
              disabled={true} />
          </div> */}
        </div>
        <div className="col-2" />
        <div className="col-3 text-right">
          {/* <div className="d-flex align-items-center">
            <label>วันที่อนุมัติ</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="approvedDate"
              value={formMasterList.approvedDate || ''}
              onChange={handleChangeMaster}
              disabled={true} />
          </div> */}
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-3">
          {/* <div className="d-flex align-items-center">
            <label>Due Date</label>
            <Datetime
              className="input-spacing-input-date"
              name="docDueDate"
              value={formMasterList.docDueDate || null}
              onChange={(date) => handleChangeDateMaster(date, 'docDueDate')}
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              inputProps={{ readOnly: true, disabled: formMasterList.docStatus === 1 ? false : true }}
            />
          </div> */}
        </div>
        <div className="col-6" />
        <div className="col-3 text-right">
          {/* <div className="d-flex align-items-center">
            <label>ผู้อนุมัติเอกสาร</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="approvedByName"
              value={formMasterList.approvedByName || ''}
              onChange={handleChangeMaster}
              disabled={true} />
          </div> */}
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-3">
          {/* <div className="d-flex align-items-center">
            <label>วิธีจัดส่ง</label>
            <select
              name="transportType"
              value={formMasterList.transportType}
              onChange={handleChangeMaster}
              className="form-select form-control input-spacing"
              disabled={formMasterList.docStatus !== 1}
            >
              {tbTransType.map((transType) => (
                <option key={transType.Trans_TypeID} value={transType.Trans_TypeID}>
                  {transType.Trans_TypeName}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        <div className="col-6" />
        <div className="col-3 text-right">
          {/* <div className="d-flex align-items-center">
            <label>หมายเหตุอนุมัติ</label>
            <input
              type="text"
              className="form-control input-spacing"
              name="approvedMemo"
              value={formMasterList.approvedMemo || ''}
              onChange={handleChangeMaster}
              disabled={true} />
          </div> */}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-4" style={{ borderRight: '2px solid #c7c8c9' }}>
          <div className="col-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="card-title">รายชื่อลูกค้า ({formDetailList.length || 0})</h4>
                <button
                  className="btn btn-outline-secondary"
                  onClick={handleArShow}
                  disabled={mode === 'U' || selectedType === ""}>
                  <i className="fas fa-search" />
                </button>
                <ArModal
                  showArModal={showArModal}
                  handleArClose={handleArClose}
                  arDataList={arDataList}
                  onRowSelectAr={onRowSelectAr}
                />
              </div>
              <div className="card-body">
                <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                  <table id="basic-datatables" className="table table-striped table-hover">
                    <thead className="thead-dark">
                      <tr>
                        <th className="text-center" style={{ width: '5%' }}>ลำดับ</th>
                        <th className="text-center" style={{ width: '30%' }}>เลขเอกสาร</th>
                        <th className="text-center" style={{ width: '60%' }}>ชื่อลูกค้า</th>
                        <th className="text-center" style={{ width: '5%' }}>ลบ</th>

                      </tr>
                    </thead>
                    <tbody>
                      {selectedType !== "" && formDetailList.length > 0 ? (
                        formDetailList.map((data, index) => (
                          <tr
                            key={data.docId || index + 1}
                            // draggable
                            // onDragStart={(e) => handleDragStart(e, index)}
                            // onDragOver={handleDragOver}
                            // onDrop={(e) => handleDrop(e, index)}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDisplayItem(data)}
                          >
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => handleMoveUp(index)}
                                disabled={index === 0}
                              >
                                <i
                                  className="fas fa-arrow-up"
                                  style={{ color: index === 0 ? '#c0c0c0' : '#EF6C00' }}
                                />
                              </button>
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => handleMoveDown(index)}
                                disabled={index === formDetailList.length - 1}
                              >
                                <i
                                  className="fas fa-arrow-down"
                                  style={{ color: index === formDetailList.length - 1 ? '#c0c0c0' : '#EF6C00' }}
                                />
                              </button>
                            </td>
                            <td className="text-center">{data.docNo || 'ไม่พบเลขเอกสาร'}</td>
                            <td className="text-left">{data.arName}</td>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(event) => handleRemove(index, event)}
                              >
                                ลบ
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">
                            <center><h5>ไม่พบข้อมูล</h5></center>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button style={{ cursor: 'pointer', color: '#EF6C00' }}
                      className={`nav-link ${activeTab === 'deliveryDetails' ? 'active' : ''}`}
                      onClick={() => setActiveTab('deliveryDetails')}>
                      รายละเอียดการส่งสินค้า
                    </button>
                  </li>
                  <li className="nav-item">
                    <button style={{ cursor: 'pointer', color: '#EF6C00' }}
                      className={`nav-link ${activeTab === 'stockRemaining' ? 'active' : ''}`}
                      onClick={() => setActiveTab('stockRemaining')}>
                      สินค้าคงเหลือ
                    </button>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                {activeTab === 'deliveryDetails' ? (
                  <>
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h4 className="card-title mb-0">
                        รายละเอียดการส่งสินค้า
                        {itemList.length > 0 && <span className="ms-2">ของ {itemList[0].docNo}</span>}
                      </h4>
                      <button
                        type="button"
                        className="btn custom-button"
                        onClick={handleBolShow}
                        disabled={mode !== 'U' || selectedType === ""}>
                        <i className="fa fa-plus"></i> สร้างใบเบิกสินค้า
                      </button>
                      {showBolModal && (
                        <BolModal
                          showBolModal={showBolModal}
                          handleBolClose={handleBolClose}
                          callInitialize={callInitialize}
                          mode={mode}
                          name={name}
                          whDocNo={maxWdNo}
                          pickingList={formMasterList}
                        />
                      )}
                    </div>
                    <div className="card-body">
                      <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <table id="basic-datatables" className="table table-striped table-hover">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center" style={{ width: '5%' }}>ลำดับ</th>
                              <th className="text-center" style={{ width: '20%' }}>รหัสสินค้า</th>
                              <th className="text-center" style={{ width: '45%' }}>ชื่อสินค้า</th>
                              <th className="text-center" style={{ width: '15%' }}>จำนวน</th>
                              <th className="text-center" style={{ width: '15%' }}>หน่วย</th>
                            </tr>
                          </thead>
                          <tbody>
                            {itemList.length > 0 ? (
                              itemList.map((item, index) => (
                                <tr key={item.soId || index + 1}>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-center">{item.itemCode || ''}</td>
                                  <td className="text-left">{item.itemName || ''}</td>
                                  <td className="text-center">{item.itemQty || ''}</td>
                                  <td className="text-center">{item.itemUnit || ''}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">
                                  <center><h5>ไม่พบรายการสินค้า</h5></center>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h4 className="card-title mb-0">
                        สินค้าคงเหลือ
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table id="basic-datatables" className="table table-striped table-hover">
                          <thead className="thead-dark">
                            <tr>
                              <th className="text-center" style={{ width: '10%' }}>รหัสสินค้า</th>
                              <th className="text-center" style={{ width: '50%' }}>ชื่อสินค้า</th>
                              <th className="text-center" style={{ width: '10%' }}>คงเหลือ</th>
                              <th className="text-center" style={{ width: '15%' }}>คลังสินค้า</th>
                              <th className="text-center" style={{ width: '15%' }}>วันที่ทำรายการล่าสุด</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formItemOnHandCaseTab.length > 0 ? (
                              formItemOnHandCaseTab.map((item, index) => (
                                <tr key={item.Item_Id || index + 1}>
                                  <td className="text-center">{item.Item_Code}</td>
                                  <td className="text-left">{item.Item_Name}</td>
                                  <td className="text-center">{item.Item_Onhand}</td>
                                  <td className="text-center">{item.WH_Name}</td>
                                  <td className="text-center">{item.Last_STC_Date ? formatDate(item.Last_STC_Date) : ''}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10}>
                                  <center>
                                    <h5>ไม่พบข้อมูล</h5>
                                  </center>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3" style={{ borderRight: '2px solid #c7c8c9' }}></div>
        {/* <div className="col-9">
          <Summary
            formMasterList={formMasterList}
            handleChangeMaster={handleChangeMaster}
            selectedDiscountValueType={selectedDiscountValueType}
            handleCheckboxChange={handleCheckboxChange}
            receiptDiscount={receiptDiscount}
            formatCurrency={formatCurrency}
            totalPrice={totalPrice}
            subFinal={subFinal}
            isVatChecked={isVatChecked}
            handleVatChange={handleVatChange}
            vatAmount={vatAmount}
            grandTotal={grandTotal}
            disabled={false}
          />
        </div> */}
      </div>
      <div className="row">
        <div className="col-12">
          <FormAction
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
            onCancel={handleCancel}
            mode={mode}
            disabled={formMasterList.docStatus === 1 ? false : true}
            footer={true}
          />
        </div>
      </div>
    </>
  );
}

export default Form;