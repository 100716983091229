export const whFormModel = () => ({
    itemCode: '',
    itemName: '',
    stcBalance: '',
    whName: '',
    quantity: '',
    zone: '',
    location: '',
    reason: '',
    updateType: ''
});