import React, { useState, useEffect } from 'react';

const PoModal = ({ showPkModal, handlePkClose, pkDataList, onRowSelectPk }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPkDataList, setFilteredPkDataList] = useState(pkDataList);

    // ฟิลเตอร์ข้อมูลเมื่อ searchTerm หรือ poDataList เปลี่ยนแปลง
    useEffect(() => {
        setFilteredPkDataList(
            pkDataList.filter(pk =>
                (pk.Doc_No && pk.Doc_No.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (pk.PK_Car_No && pk.PK_Car_No.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        );
    }, [searchTerm, pkDataList]);

    useEffect(() => {
        if (showPkModal) {
            setSearchTerm('');
        }
    }, [showPkModal]);

    return (
        <>
            {/* Modal */}
            <div className={`modal ${showPkModal ? 'show' : ''}`} style={{ display: showPkModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">รายชื่อใบ Picking List</h5>
                            <button type="button" className="close" onClick={handlePkClose}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="ค้นหาเลขที่เอกสาร (PK) หรือ ทะเบียนรถ"
                                    value={searchTerm || ''}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                <table className="table table-striped table-hover">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th className="text-center" style={{ width: '25%' }}>เลขที่เอกสาร (PK)</th>
                                            <th className="text-center" style={{ width: '15%' }}>ทะเบียนรถ</th>
                                            <th className="text-center" style={{ width: '20%' }}>คนขับคนที่ 1</th>
                                            <th className="text-center" style={{ width: '20%' }}>คนขับคนที่ 2</th>
                                            <th className="text-center" style={{ width: '20%' }}>คนขับคนที่ 3</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPkDataList && filteredPkDataList.length > 0 ? (
                                            filteredPkDataList.map((pk, index) => (
                                                <tr
                                                    key={pk.Doc_Id || index + 1}
                                                    onClick={() => onRowSelectPk(pk)}
                                                    style={{ cursor: 'pointer' }}>
                                                    <td className="text-center">{pk.Doc_No}</td>
                                                    <td className="text-left">{pk.PK_Car_No || '-'}</td>
                                                    <td className="text-left">{pk.Driver1 || '-'}</td>
                                                    <td className="text-left">{pk.Driver2 || '-'}</td>
                                                    <td className="text-left">{pk.Driver3 || '-'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="10">
                                                    <center>
                                                        <h5>ไม่พบข้อมูล</h5>
                                                    </center>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" onClick={handlePkClose}>
                                ปิด
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Overlay */}
            {showPkModal && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default PoModal;