import React, { useState, useEffect } from 'react';

// Components
import Sidebar from '../../components/Sidebar';
import Main from '../../components/SellProducts/PickingList/Main';
import Form from '../../components/SellProducts/PickingList/Form';
import { getDataWithComp, getDocStatusColour, getAlert, getMaxDocNo } from '../../../utils/SamuiUtils';

function PickingList() {
    const [mode, setMode] = useState('');
    const [dataMasterList, setDataMasterList] = useState([]);
    const [statusColours, setStatusColours] = useState([]);
    const [reqStcStatusColours, setReqStcStatusColours] = useState([]);
    const [maxDocNo, setMaxDocNo] = useState();

    useEffect(() => {
        initialize();
    }, []);

    const fetchRealtime = async () => {
        try {
            const masterList = await getDataWithComp('API_0801_PKList_H', 'ORDER BY Doc_No DESC');
            const docStatusColour = await getDocStatusColour('PKList', 'Doc_Status');
            const reqStcStatusColour = await getDocStatusColour('PKList', 'Request_STC_Status');

            if (masterList && masterList.length > 0) {
                const sortedData = masterList.sort((a, b) => a.Doc_No - b.Doc_No);
                setDataMasterList(sortedData);
            }

            if (docStatusColour && docStatusColour.length > 0) {
                setStatusColours(docStatusColour);
            }

            if (reqStcStatusColour && reqStcStatusColour.length > 0) {
                setReqStcStatusColours(reqStcStatusColour);
            }

            // หาค่าสูงของ DocNo ใน PKList_H
            const findMaxDocNo = await getDataWithComp('PKList_H', 'ORDER BY Doc_No DESC');
            const maxDoc = getMaxDocNo(findMaxDocNo, 'PK');
            setMaxDocNo(maxDoc);
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const initialize = async () => {
        try {
            setMode('S');
            fetchRealtime(); // เรียกใช้งาน fetchRealtime เพื่อโหลดข้อมูลเมื่อ component โหลดครั้งแรก
        } catch (error) {
            getAlert('FAILED', error.message);
        }
    };

    const onPageInsert = () => {
        setMode('I')
    };

    const onRowSelected = (docNo) => {
        setMaxDocNo(docNo);
        setMode('U');
    };

    return (
        <div className="PickingList">
            <div className="wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="container">
                        <div className="page-inner">
                            {mode === 'S' ? (
                                <Main
                                    masterList={dataMasterList}
                                    statusColours={statusColours}
                                    reqStcStatusColours={reqStcStatusColours}
                                    name={'Picking List'}
                                    onPageInsert={() => onPageInsert()}
                                    onRowSelected={(payNo) => onRowSelected(payNo)}
                                />
                            ) : (
                                <Form callInitialize={initialize} mode={mode} name={'Picking List'} maxDocNo={maxDocNo} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PickingList;